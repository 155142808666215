import * as Styles from "../../../styles";
import { style as s } from "typestyle";

export const component = s({});

export const textInput = s({ width: "100%", marginBottom: 16 });

export const error = s({
  color: Styles.colors.error,
  fontSize: 14,
});

export const input = s({
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
  gap: 12,
});

export const imageContainer = s({
  height: "200px",
  margin: "16px 0px",
});

export const selectContainer = s({
  marginBottom: 12,
  display: "flex",
  gap: 12,
  alignItems: "center",
});

export const image = s({
  height: "100%",
});

export const button = s({
  margin: "auto",
  marginBottom: 12,
});

export const picker = s({
  marginBottom: 16,
  width: "40%",
});

export const checkboxRow = s({
  display: "flex",
  alignItems: "center",
  gap: 8,
  marginLeft: -12,
  marginBottom: 24,
});

export const clearButton = s({
  padding: 8,
  backgroundColor: "lightblue",
  cursor: "pointer",
  borderRadius: 8,
  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
});

export const smallHeader = s({
  fontSize: 32,
  fontWeight: 600,
  marginBottom: 24,
});

export const xsHeader = s({
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 12,
});

export const questionsContainer = s({
  display: "flex",
  gap: 24,
  alignItems: "flex-start",
  marginBottom: 32,
});

export const questionsSection = s({
  border: "1px solid black",
  borderRadius: 12,
  padding: 16,
});

export const questionContainer = s({
  display: "flex",
  gap: 12,
  alignItems: "center",
  justifyContent: "space-between",
});

export const question = s({
  border: "1px solid gray",
  borderRadius: 12,
  padding: `4px 8px`,
  marginBottom: 8,
  cursor: "pointer",
});

export const selectedQuestion = s({
  borderColor: "black",
  backgroundColor: Styles.colors.softYellow,
});

export const questionOption = s({
  display: "flex",
  alignItems: "center",
  gap: 8,
});
