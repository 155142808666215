import React from "react";
import * as style from "./Users.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../models";
import * as Components from "../../components";
import { UserActions } from "../../store/actions";
import { classes } from "typestyle";
import { RootState } from "../../store/reducers";
import { Checkbox, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

interface StateProps {
  user: Models.Users.ShakeUser;
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteUserModal: React.FC<StateProps> = ({
  user,
  onClose,
  onSuccess,
}) => {
  const dispatch = useDispatch();

  const [submitError, setSubmitError] = React.useState("");
  const [confirming, setConfirming] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);

  const [deleteUser, deleteUserStatus] = useMutation(
    Mutation.DELETE_USER_MUTATION,
  );

  const onSubmit = () => {
    setInProgress(true);
    deleteUser({
      variables: {
        userId: user.id,
      },
    })
      .then((res) => {
        setInProgress(false);
        if (res?.data?.adminDeleteUser?.success) {
          toast.success("Success");
          dispatch(UserActions.DeleteUserSuccess(user.id));
          setConfirming(false);
          onSuccess();
        } else {
          toast.error(
            "Something went wrong: " + res?.data?.adminDeleteUser?.error,
          );
        }
      })
      .catch((e) => {
        setInProgress(false);
        toast.error(JSON.parse(JSON.stringify(e)).message);
      });
  };

  return (
    <div className={style.modalBackground}>
      <div className={style.modalContainer}>
        <div
          className={style.modalTitle}
        >{`Delete User - ${user.displayName}`}</div>
        <div className={style.textContent}>
          {!confirming
            ? "DO NOT DELETE ANYBODY OTHER THAN SHAKE EMPLOYEES FOR TESTING"
            : "TRIPLE CHECK WHO YOU ARE DELETING"}
        </div>
        <div style={{ textAlign: "center", marginBottom: 12 }}>
          If a user requested account deletion, just update their account status
          to LOCKED
        </div>
        <Components.Button
          label="Delete"
          onClick={!confirming ? () => setConfirming(true) : onSubmit}
          inProgress={inProgress}
          className={style.button}
        />
        <Components.Button
          label="Close"
          onClick={onClose}
          className={style.button}
        />
      </div>
    </div>
  );
};
