import * as Models from "../../../models";
/**
 * State
 */

export interface CreateSurveyState {
  name: string;
  description: string;
  availableStartsAtUtc: Date;
  availableEndsAtUtc: Date;
  maximumResponses: number | null;
  rewardAmount: number;
  sponsorId: number | null;
  currentQuestionIndex: number;
  surveyQuestions: Record<
    number,
    {
      question: string;
      description: string;
      questionType: Models.Promo.SurveyQuestionType;
      scaleMin: number | null;
      scaleMax: number | null;
      sortOrder: number;
      surveyQuestionOptions: string[];
      error: string;
    }
  >;
  submitError: string;
  readyToSubmit: boolean;
}

export const initialCreateSurveyState: CreateSurveyState = {
  name: "",
  description: "",
  availableStartsAtUtc: new Date(),
  availableEndsAtUtc: new Date(),
  maximumResponses: null,
  rewardAmount: 200,
  sponsorId: null,
  currentQuestionIndex: 0,
  surveyQuestions: {
    0: {
      question: "Question 1",
      description: "",
      questionType: Models.Promo.SurveyQuestionType.OPEN,
      scaleMin: null,
      scaleMax: null,
      sortOrder: 1,
      surveyQuestionOptions: [],
      error: "",
    },
  },
  submitError: "",
  readyToSubmit: false,
};

/**
 * Actions
 */

export interface UpdateSurvey {
  type: "UPDATE_SURVEY_FIELD";
  payload: {
    field:
      | "name"
      | "description"
      | "availableStartsAtUtc"
      | "availableEndsAtUtc"
      | "maximumResponses"
      | "rewardAmount"
      | "sponsorId";
    value: string | number | Date | null;
  };
}

export interface UpdateQuestion {
  type: "UPDATE_SURVEY_QUESTION_FIELD";
  payload: {
    field:
      | "question"
      | "description"
      | "questionType"
      | "scaleMin"
      | "scaleMax"
      | "sortOrder";
    value: string | number | Models.Promo.SurveyQuestionType | null;
  };
}

export interface AddQuestionOption {
  type: "ADD_QUESTION_OPTION";
  payload: {
    value: string;
  };
}

export interface RemoveQuestionOption {
  type: "REMOVE_QUESTION_OPTION";
  payload: {
    questionOptionIndex: number;
  };
}

export interface UpdateCurrentQuestionIndex {
  type: "UPDATE_CURRENT_QUESTION_INDEX";
  payload: {
    newIndex: number;
  };
}

export interface CreateQuestion {
  type: "CREATE_QUESTION";
}

export interface DeleteQuestion {
  type: "DELETE_QUESTION";
  payload: {
    questionIndex: number;
  };
}

export interface ResetSurveyState {
  type: "RESET_SURVEY_STATE";
}

export interface Submit {
  type: "SUBMIT";
}

export interface SubmitSuccess {
  type: "SUBMIT_SUCCESS";
}

export interface SubmitFailure {
  type: "SUBMIT_FAILURE";
}

export type CreateSurveyAction =
  | UpdateSurvey
  | UpdateQuestion
  | CreateQuestion
  | DeleteQuestion
  | AddQuestionOption
  | RemoveQuestionOption
  | UpdateCurrentQuestionIndex
  | ResetSurveyState
  | Submit
  | SubmitSuccess
  | SubmitFailure;

/**
 * Reducer
 */

export const createSurveyReducer = (
  state: CreateSurveyState,
  action: CreateSurveyAction,
) => {
  switch (action.type) {
    case "UPDATE_SURVEY_FIELD": {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case "UPDATE_SURVEY_QUESTION_FIELD": {
      return {
        ...state,
        surveyQuestions: {
          ...state.surveyQuestions,
          [state.currentQuestionIndex]: {
            ...state.surveyQuestions[state.currentQuestionIndex],
            [action.payload.field]: action.payload.value,
            ...(action.payload.field === "questionType"
              ? action.payload.value ===
                Models.Promo.SurveyQuestionType.RATING_SCALE
                ? { scaleMin: 1, scaleMax: 10 }
                : { scaleMin: null, scaleMax: null }
              : {}),
            ...(action.payload.field === "questionType"
              ? { surveyQuestionOptions: [] }
              : {}),
          },
        },
      };
    }
    case "CREATE_QUESTION": {
      const questionsArray = Object.values(state.surveyQuestions);
      let newQuestionIndex = 1;
      let currentHighestSortOrder = -1;
      questionsArray.forEach((q) => {
        if (q.sortOrder > currentHighestSortOrder) {
          currentHighestSortOrder = q.sortOrder;
        }
      });
      while (!!state.surveyQuestions[newQuestionIndex]) {
        newQuestionIndex++;
      }
      return {
        ...state,
        surveyQuestions: {
          ...state.surveyQuestions,
          [newQuestionIndex]: {
            question: `Question ${newQuestionIndex + 1}`,
            description: "",
            questionType: Models.Promo.SurveyQuestionType.OPEN,
            scaleMin: null,
            scaleMax: null,
            sortOrder: currentHighestSortOrder + 1,
            surveyQuestionOptions: [],
            error: "",
          },
        },
        currentQuestionIndex: newQuestionIndex,
      };
    }
    case "DELETE_QUESTION": {
      const newQuestions = { ...state.surveyQuestions };
      delete newQuestions[action.payload.questionIndex];
      return {
        ...state,
        surveyQuestions: newQuestions,
        currentQuestionIndex:
          state.currentQuestionIndex === action.payload.questionIndex
            ? Number.parseInt(Object.keys(newQuestions)[0])
            : state.currentQuestionIndex,
      };
    }
    case "ADD_QUESTION_OPTION": {
      return {
        ...state,
        surveyQuestions: {
          ...state.surveyQuestions,
          [state.currentQuestionIndex]: {
            ...state.surveyQuestions[state.currentQuestionIndex],
            surveyQuestionOptions: [
              ...state.surveyQuestions[state.currentQuestionIndex]
                .surveyQuestionOptions,
              action.payload.value,
            ],
          },
        },
      };
    }
    case "REMOVE_QUESTION_OPTION": {
      const newQuestionOptionsArray = [
        ...state.surveyQuestions[state.currentQuestionIndex]
          .surveyQuestionOptions,
      ].splice(action.payload.questionOptionIndex, 1);
      return {
        ...state,
        surveyQuestions: {
          ...state.surveyQuestions,
          [state.currentQuestionIndex]: {
            ...state.surveyQuestions[state.currentQuestionIndex],
            surveyQuestionOptions: newQuestionOptionsArray,
          },
        },
      };
    }
    case "UPDATE_CURRENT_QUESTION_INDEX": {
      return {
        ...state,
        currentQuestionIndex: action.payload.newIndex,
      };
    }
    case "RESET_SURVEY_STATE": {
      return initialCreateSurveyState;
    }
    case "SUBMIT": {
      console.log(state);
      return {
        ...state,
        readyToSubmit: true,
      };
    }
    case "SUBMIT_SUCCESS": {
      return initialCreateSurveyState;
    }
    case "SUBMIT_FAILURE": {
      return {
        ...state,
        readyToSubmit: false,
      };
    }
  }
};
