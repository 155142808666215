import { gql } from "@apollo/client";

export const CREATE_SWIPE_CONTEST_MUTATION = gql`
  mutation adminCreateSwipeContest(
    $matchupId: Int!
    $availableStartsAtUtc: DateTime!
    $availableEndsAtUtc: DateTime!
    $imageUrl: String
    $swipeOptions: [CreateSwipeOptionArgs!]!
  ) {
    adminCreateSwipeContest(
      matchupId: $matchupId
      availableStartsAtUtc: $availableStartsAtUtc
      availableEndsAtUtc: $availableEndsAtUtc
      imageUrl: $imageUrl
      swipeOptions: $swipeOptions
    ) {
      id
    }
  }
`;
