import { validation } from "../../../utility";
import * as Models from "../../../models";

/**
 * State
 */

interface CreateSwipeOption {
  matchupOptionId: number;
  sortOrder: number;
  backgroundColor?: string;
  displayName?: string;
  description?: string;
  imageInB64?: string;
  imageUrl?: string;
}

export interface CreateSwipeContestState {
  matchup?: Models.Contests.Matchup;
  availableStartsAtUtc: Date | null;
  availableEndsAtUtc: Date | null;
  imageInB64?: string;
  swipeOptions: Record<number, CreateSwipeOption>;
  readyToSubmit: boolean;
  submitError?: string;
}

export const createSwipeContestDefaultState: CreateSwipeContestState = {
  readyToSubmit: false,
  swipeOptions: {},
  availableStartsAtUtc: null,
  availableEndsAtUtc: null,
};

/**
 * Actions
 */
export interface UpdateFieldAction {
  type: "UPDATE_SWIPE_FIELD";
  payload: {
    field: "availableStartsAtUtc" | "availableEndsAtUtc" | "imageInB64";
    value: string | Date;
  };
}

export interface UpdateSwipeOptionFieldAction {
  type: "UPDATE_SWIPE_OPTION_FIELD";
  payload: {
    optionId: number;
    field: "backgroundColor" | "displayName" | "description" | "imageInB64";
    value: string;
  };
}

export interface SelectMatchupAction {
  type: "SELECT_MATCHUP";
  payload: {
    matchup: Models.Contests.Matchup;
  };
}

export interface ClearStateAction {
  type: "CLEAR_STATE";
}

export interface SubmitAction {
  type: "SUBMIT";
}

export interface SubmitSuccessAction {
  type: "SUBMIT_SUCCESS";
}

export interface SubmitFailureAction {
  type: "SUBMIT_FAILURE";
  payload: {
    error: string;
  };
}

export type CreateSwipeContestAction =
  | UpdateFieldAction
  | UpdateSwipeOptionFieldAction
  | SelectMatchupAction
  | ClearStateAction
  | SubmitAction
  | SubmitSuccessAction
  | SubmitFailureAction;

/**
 * Reducer
 */

export const createSwipeContestReducer = (
  state: CreateSwipeContestState,
  action: CreateSwipeContestAction,
) => {
  switch (action.type) {
    case "UPDATE_SWIPE_FIELD": {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case "UPDATE_SWIPE_OPTION_FIELD": {
      return !!state.swipeOptions[action.payload.optionId]
        ? {
            ...state,
            swipeOptions: {
              ...state.swipeOptions,
              [action.payload.optionId]: {
                ...state.swipeOptions[action.payload.optionId],
                [action.payload.field]: action.payload.value,
                ...(action.payload.field === "imageInB64"
                  ? { imageUrl: undefined }
                  : {}),
              },
            },
          }
        : state;
    }
    case "SELECT_MATCHUP": {
      if (action.payload.matchup.matchupOptions.length !== 2) {
        return {
          ...state,
          submitError:
            "Can only create Swipe Contests with Matchups that have exactly 2 Matchup Options",
        };
      } else {
        const newSwipeOptionsDict: Record<number, CreateSwipeOption> = {};
        action.payload.matchup.matchupOptions.forEach((opt, i) => {
          newSwipeOptionsDict[opt.matchupOption.id] = {
            matchupOptionId: opt.matchupOption.id,
            sortOrder: opt.sortOrder ?? i,
            backgroundColor: i === 0 ? "#990808" : "#008625",
            displayName: !!opt.matchupOptionVisualization?.displayName
              ? opt.matchupOptionVisualization.displayName
              : opt.matchupOption.name,
            imageUrl: opt.matchupOptionVisualization?.imageUrl,
            description: opt.matchupOptionVisualization.description,
          };
        });
        return {
          ...state,
          matchup: action.payload.matchup,
          availableEndsAtUtc: new Date(action.payload.matchup.eventStartTime),
          swipeOptions: newSwipeOptionsDict,
        };
      }
    }
    case "SUBMIT": {
      let submitError = "";
      const swipeOptions = Object.values(state.swipeOptions);
      if (!state.matchup) {
        submitError = "Must select matchup";
      } else if (
        !state.availableStartsAtUtc ||
        !state.availableEndsAtUtc ||
        state.availableEndsAtUtc < state.availableStartsAtUtc
      ) {
        submitError = "Please select valid dates";
      } else if (swipeOptions.length !== 2) {
        submitError = "Must have exactly 2 swipe options";
      } else if (swipeOptions.filter((opt) => !!opt.description).length === 1) {
        submitError = "Cannot have only 1 description";
      } else if (
        swipeOptions.filter((opt) => !!opt.imageInB64 || !!opt.imageUrl)
          .length === 1
      ) {
        submitError = "Cannot have only 1 image";
      } else {
        swipeOptions.forEach((opt) => {
          if (!!opt.imageInB64 && !!opt.imageUrl) {
            submitError = "Cannot have both new image and image url";
          } else if (!opt.backgroundColor) {
            submitError = "Must select a background color";
          }
        });
      }

      return { ...state, readyToSubmit: !submitError, submitError };
    }
    case "CLEAR_STATE":
    case "SUBMIT_SUCCESS": {
      return createSwipeContestDefaultState;
    }
    case "SUBMIT_FAILURE": {
      return {
        ...state,
        readyToSubmit: false,
        errors: {
          submit: action.payload.error,
        },
      };
    }
  }
};
