import { gql } from "@apollo/client";

export const UPDATE_SWIPE_CONTEST_MUTATION = gql`
  mutation adminUpdateSwipeContest(
    $swipeContestId: Int!
    $availableStartsAtUtc: DateTime
    $availableEndsAtUtc: DateTime
    $status: SwipeContestStatus
  ) {
    adminUpdateSwipeContest(
      swipeContestId: $swipeContestId
      availableStartsAtUtc: $availableStartsAtUtc
      availableEndsAtUtc: $availableEndsAtUtc
      status: $status
    ) {
      id
    }
  }
`;
