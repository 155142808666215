export {
  component,
  table,
  row,
  rowItem,
} from "../../Rewards/ViewBrands/ViewBrands.style";
export {
  modalContainer,
  modalContainerItems,
  redButton,
} from "../../ContestTemplates/ResultMatchups/ResultMatchups.style";
import { style as s } from "typestyle";
import * as Styles from "../../../styles";

export const exportButtonContainer = s({
  paddingBottom: 16,
});

export const exportButton = s({
  textDecoration: "none",
  color: Styles.colors.black1,
});
