import { Brand } from "./reward";
import { ShakeUser } from "./user";

export interface Quest {
  id: number;
  createdAtUtc: Date;
  updatedAtUtc: Date;
  adminUpdatedBy: string;
  name: string;
  description?: string;
  questType: QuestType;
  tier: number;
  requiredActionCount: number;
  rewardType: QuestRewardType;
  rewardAmount: number;
  status: QuestStatus;
}

export interface QuestClaim {
  userId: number;
  user: ShakeUser;
  questId: number;
  quest: Quest;
  createdAtUtc: Date;
  rewardType: QuestRewardType;
  rewardAmount: number;
}

export enum QuestType {
  CREATE_CONTEST_H2H = "CREATE_CONTEST_H2H",
  CREATE_CONTEST_GROUP = "CREATE_CONTEST_GROUP",
  WIN_CONTEST_H2H = "WIN_CONTEST_H2H",
  WIN_CONTEST_GROUP = "WIN_CONTEST_GROUP",
  REFER_A_FRIEND = "REFER_A_FRIEND",
}

export enum QuestRewardType {
  TOKEN = "TOKEN",
}

export enum QuestStatus {
  LIVE = "LIVE",
  DISABLED = "DISABLED",
}

export interface Survey {
  id: number;
  createdAtUtc: Date;
  updatedAtUtc: Date;
  adminUpdatedBy: string;
  name: string;
  description?: string;
  availableStartsAtUtc: Date;
  availableEndsAtUtc: Date;
  status: SurveyStatus;
  maximumResponses?: number;
  rewardAmount: number;
  sponsorId?: number;
  sponsor?: Brand;
  surveyQuestions: SurveyQuestion[];
  responses: SurveyResponse[];
  _count: {
    responses: number;
  };
}

export interface SurveyQuestionCreateArgs {
  question: string;
  description?: string;
  questionType: SurveyQuestionType;
  scaleMin?: number;
  scaleMax?: number;
  sortOrder: number;
  surveyQuestionOptions: SurveyQuestionOption[];
}

export interface SurveyQuestion {
  id: number;
  createdAtUtc: Date;
  updatedAtUtc: Date;
  adminUpdatedBy: string;
  surveyId: number;
  survey: Survey;
  question: string;
  description?: string;
  questionType: SurveyQuestionType;
  scaleMin?: number;
  scaleMax?: number;
  status: SurveyQuestionStatus;
  sortOrder: number;
  surveyQuestionOptions: SurveyQuestionOption[];
  answers: SurveyQuestionAnswer[];
}

export interface SurveyQuestionOption {
  name: string;
  description?: string;
  sortOrder: number;
}

export interface SurveyResponse {
  id: number;
  surveyId: number;
  survey: Survey;
  userId: number;
  user: ShakeUser;
  createdAtUtc: Date;
  rewardAmount: number;
  surveyQuestionAnswers: SurveyQuestionAnswer[];
}

export interface SurveyQuestionAnswer {
  id: number;
  surveyResponseId: number;
  surveyResponse: SurveyResponse;
  surveyQuestionId: number;
  surveyQuestion: SurveyQuestion;
  surveyQuestionOptions: SurveyQuestionOption[];
  answerText?: string;
  likertAnswer?: LikertAnswer;
  ratingAnswer?: number;
}

export enum SurveyStatus {
  DRAFT = "DRAFT",
  LIVE = "LIVE",
  DISABLED = "DISABLED",
  COMPLETE = "COMPLETE",
}

export enum SurveyQuestionType {
  RADIO_SINGLE = "RADIO_SINGLE",
  RADIO_MULTIPLE = "RADIO_MULTIPLE",
  OPEN = "OPEN",
  RATING_SCALE = "RATING_SCALE",
  LIKERT_SCALE = "LIKERT_SCALE",
}

export enum SurveyQuestionStatus {
  LIVE = "LIVE",
  DISABLED = "DISABLED",
}

export enum LikertAnswer {
  STRONGLY_DISAGREE = "STRONGLY_DISAGREE",
  DISAGREE = "DISAGREE",
  NEUTRAL = "NEUTRAL",
  AGREE = "AGREE",
  STRONGLY_AGREE = "STRONGLY_AGREE",
}
