import React from "react";
import * as style from "./Surveys.style";
import { classes } from "typestyle";
import { CreateSurvey } from "./CreateSurvey";
import { ViewSurveys } from "./ViewSurveys";

export const Surveys = () => {
  const [activeTab, setActiveTab] = React.useState("CreateSurvey");
  return (
    <div className={style.component}>
      <div className={style.title}>{`Surveys`}</div>
      <div className={style.topNav}>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "CreateSurvey" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("CreateSurvey")}
        >
          Create Survey
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "ViewSurveys" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("ViewSurveys")}
        >
          View Surveys
        </div>
      </div>
      {activeTab == "CreateSurvey" && <CreateSurvey />}
      {activeTab == "ViewSurveys" && <ViewSurveys />}
    </div>
  );
};
