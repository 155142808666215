import React from "react";
import * as style from "./ViewSurveys.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";
import * as Models from "../../../models";
import { Icon } from "../../../components";
import { classes } from "typestyle";
import { toast } from "react-toastify";
import { toShortDateAndTimeUTC } from "../../../utility/date";
import { UpdateSurvey } from "./UpdateSurvey";

export const ViewSurveys: React.FC = () => {
  const client = useApolloClient();
  const [selectedSurveyId, setSelectedSurveyId] = React.useState<number | null>(
    null,
  );
  const [surveys, setSurveys] = React.useState<Models.Promo.Survey[]>([]);

  const getSurveys = (skip?: number) => {
    client
      .query({
        query: Query.GET_SURVEYS_QUERY,
        fetchPolicy: "no-cache",
        variables: {
          skip: skip ?? 0,
          take: 50,
        },
      })
      .then((res) => {
        if (res.data.adminGetSurveys) {
          setSurveys(res.data.adminGetSurveys);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed to get Surveys");
      });
  };
  React.useEffect(() => {
    getSurveys();
  }, []);

  const selectedSurvey = React.useMemo(() => {
    return !selectedSurveyId
      ? null
      : surveys.find((b) => b.id === selectedSurveyId);
  }, [selectedSurveyId]);

  return (
    <div className={style.component}>
      {!!surveys ? (
        <div className={style.table}>
          {!!surveys.length ? (
            surveys.map((survey) => (
              <div
                className={classes(
                  style.row,
                  survey.id === selectedSurveyId ? style.selectedRow : "",
                )}
                key={survey.id}
                onClick={() => {
                  if (survey.status !== Models.Promo.SurveyStatus.COMPLETE) {
                    setSelectedSurveyId(
                      selectedSurveyId === survey.id ? null : survey.id,
                    );
                  }
                }}
              >
                <div className={style.rowItem}>{survey.name}</div>
                <div className={style.rowItem}>
                  <div>
                    <div>{`${toShortDateAndTimeUTC(
                      new Date(survey.availableStartsAtUtc),
                    )}`}</div>
                    <div>{`${toShortDateAndTimeUTC(
                      new Date(survey.availableEndsAtUtc),
                    )}`}</div>
                  </div>
                </div>
                <div className={style.rowItem}>
                  Responses: {survey._count.responses}
                </div>
                <div className={style.rowItem}>{survey.status}</div>
              </div>
            ))
          ) : (
            <div
              className={classes(style.row, style.rowItem)}
              style={{ fontWeight: 600 }}
            >
              No Surveys To Show
            </div>
          )}
        </div>
      ) : (
        <Icon.Spinner size={24} />
      )}
      {!!selectedSurvey && (
        <UpdateSurvey
          onClose={() => {
            setSelectedSurveyId(null);
            getSurveys();
          }}
          survey={selectedSurvey}
        />
      )}
    </div>
  );
};
