import { gql } from "@apollo/client";

export const GET_PRIZES_QUERY = gql`
  query adminGetPrizes(
    $delivered: Boolean!
    $deliveryEmailExists: Boolean!
    $take: Int!
    $skip: Int!
  ) {
    adminGetPrizes(
      delivered: $delivered
      deliveryEmailExists: $deliveryEmailExists
      take: $take
      skip: $skip
    ) {
      id
      rewardId
      rewardAmount
      reward {
        brand {
          name
          imageUrl
        }
      }
      contestId
      contest {
        contestTemplate {
          name
        }
      }
      userId
      user {
        displayName
      }
      deliveryEmail
      delivered
      updatedAtUtc
    }
  }
`;
