import { gql } from "@apollo/client";

export const GET_SWIPE_CONTESTS_QUERY = gql`
  query adminSwipeContests($statuses: [SwipeContestStatus!]) {
    adminSwipeContests(statuses: $statuses) {
      id
      status
      updatedAtUtc
      createdAtUtc
      updatedByAdmin
      matchupId
      matchup {
        eventStartTime
        name
      }
      availableStartsAtUtc
      availableEndsAtUtc
      imageUrl
      swipeOptions {
        sortOrder
        matchupOption {
          id
          name
        }
        backgroundColor
        displayName
        description
        imageUrl
      }
    }
  }
`;
