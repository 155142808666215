import React from "react";
import * as style from "./CreateSwipes.style";
import { classes, stylesheet } from "typestyle";
import * as Components from "../../../components";
import * as Models from "../../../models";
import * as Utility from "../../../utility";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { RewardActions } from "../../../store/actions";
import { Checkbox, MenuItem, Select } from "@mui/material";
import {
  createSwipeContestDefaultState,
  createSwipeContestReducer,
} from "./createSwipesReducer";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";

export const CreateSwipes = () => {
  const client = useApolloClient();

  const [matchups, setMatchups] = React.useState<Models.Contests.Matchup[]>([]);
  const [inProgress, setInProgress] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  /** Image  */
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [selectedFileOption1, setSelectedFileOption1] =
    React.useState<File | null>(null);
  const [selectedFileOption2, setSelectedFileOption2] =
    React.useState<File | null>(null);

  const [createSwipeContest, createSwipeContestData] = useMutation(
    Mutation.CREATE_SWIPE_CONTEST_MUTATION,
  );

  const [state, dispatch] = React.useReducer(
    createSwipeContestReducer,
    createSwipeContestDefaultState,
  );

  const getMatchups = (skip?: number) => {
    client
      .query({
        query: Query.GET_MATCHUPS_QUERY,
        fetchPolicy: "no-cache",
        variables: {
          take: 150,
          skip: skip ?? 0,
          eventStartTimeGreaterThan: new Date(),
          statuses: [Models.Contests.MatchupStatus.PENDING],
        },
      })
      .then((res) => {
        if (res?.data?.adminMatchups?.length) {
          setMatchups(
            res.data.adminMatchups.filter(
              (matchup: Models.Contests.Matchup) =>
                matchup.matchupOptions.length === 2,
            ),
          );
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    getMatchups();
  }, []);

  React.useEffect(() => {
    if (state.readyToSubmit && state.matchup) {
      setInProgress(true);
      let variables: any = {
        matchupId: state.matchup.id,
        availableStartsAtUtc: state.availableStartsAtUtc,
        availableEndsAtUtc: state.availableEndsAtUtc,
        imageB64: state.imageInB64,
        swipeOptions: Object.values(state.swipeOptions),
      };
      createSwipeContest({
        variables,
      })
        .then((res) => {
          if (res.data.adminCreateSwipeContest) {
            setInProgress(false);
            setSelectedFile(null);
            dispatch({ type: "SUBMIT_SUCCESS" });
            toast.success("Swipe Contest Created as Draft");
          } else {
            dispatch({
              type: "SUBMIT_FAILURE",
              payload: { error: res.errors?.toString() ?? "" },
            });
            toast.error(
              `Unable to create swipe contest: ${res.errors?.toString()}`,
            );
          }
        })
        .catch((e) => {
          setInProgress(false);
          toast.error(`Unable to create swipe contest: ${e.message}`);
          dispatch({ type: "SUBMIT_FAILURE", payload: { error: e.message } });
          console.log(e);
        });
    }
  }, [state.readyToSubmit]);

  const swipeOptionsArr = React.useMemo(() => {
    return Object.values(state.swipeOptions).sort((a, b) =>
      a.sortOrder < b.sortOrder ? -1 : 1,
    );
  }, [state.swipeOptions]);
  React.useEffect(() => {
    if (selectedFile != null && !!state.matchup) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        dispatch({
          type: "UPDATE_SWIPE_FIELD",
          payload: {
            field: "imageInB64",
            value: fileReader!.result!.toString(),
          },
        });
      };
    }
  }, [selectedFile]);
  React.useEffect(() => {
    if (selectedFileOption1 != null && !!state.matchup) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFileOption1);
      fileReader.onload = () => {
        dispatch({
          type: "UPDATE_SWIPE_OPTION_FIELD",
          payload: {
            optionId: swipeOptionsArr[0].matchupOptionId,
            field: "imageInB64",
            value: fileReader!.result!.toString(),
          },
        });
      };
    }
  }, [selectedFileOption1]);
  React.useEffect(() => {
    if (selectedFileOption2 != null && !!state.matchup) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFileOption2);
      fileReader.onload = () => {
        dispatch({
          type: "UPDATE_SWIPE_OPTION_FIELD",
          payload: {
            optionId: swipeOptionsArr[1].matchupOptionId,
            field: "imageInB64",
            value: fileReader!.result!.toString(),
          },
        });
      };
    }
  }, [selectedFileOption2]);

  const matchupHasImage = state.matchup && state.imageInB64;

  return (
    <div className={style.component}>
      <div className={style.majorSection}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className={style.matchupsContainer}>
            {!!matchups.length ? (
              matchups.map((matchup) => (
                <div
                  className={
                    !!state.matchup && state.matchup.id === matchup.id
                      ? style.selectedMatchup
                      : style.matchup
                  }
                  onClick={() => {
                    if (!!state.matchup && state.matchup.id === matchup.id) {
                      dispatch({ type: "CLEAR_STATE" });
                    } else {
                      dispatch({
                        type: "SELECT_MATCHUP",
                        payload: { matchup },
                      });
                    }
                  }}
                  key={matchup.id}
                >
                  {matchup.name}
                </div>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>
                <Components.Icon.Spinner size={24} />
              </div>
            )}
          </div>
          <div>
            <Components.Button
              onClick={() => dispatch({ type: "SUBMIT" })}
              label="Submit"
              inProgress={inProgress}
            />
            {!!state.submitError && (
              <div className={style.error}>{state.submitError}</div>
            )}
          </div>
        </div>
        <div className={style.previewSection}>
          {!!state.matchup && (
            <div className={style.previewContainer}>
              <div className={style.previewOptionsContainer}>
                {!!state.imageInB64 && (
                  <div className={style.previewMatchupImageContainer}>
                    <img
                      src={state.imageInB64}
                      className={style.previewImage}
                    />
                  </div>
                )}
                {swipeOptionsArr.map((swipeOption, i) => (
                  <div
                    className={style.previewOption}
                    style={
                      !!swipeOption.backgroundColor
                        ? { backgroundColor: swipeOption.backgroundColor }
                        : {}
                    }
                    key={swipeOption.matchupOptionId}
                  >
                    <div className={style.previewOptionImageSection}>
                      {!matchupHasImage &&
                      (!!swipeOption.imageUrl || !!swipeOption.imageInB64) ? (
                        <img
                          className={style.previewImage}
                          src={swipeOption.imageUrl ?? swipeOption.imageInB64}
                        />
                      ) : (
                        <div className={style.previewImage} />
                      )}
                    </div>
                    <div className={style.previewOptionNameSection}>
                      {swipeOption.displayName}
                    </div>
                    <div className={style.previewOptionBottomSection}>
                      <div
                        className={style.previewOptionDescription}
                        style={
                          !swipeOption.description
                            ? { backgroundColor: "transparent" }
                            : {}
                        }
                      >
                        {swipeOption.description}
                      </div>
                      <div className={style.previewOptionSwipeButton}>
                        {i === 0 ? "< Swipe" : "Swipe >"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={style.matchupName}>{state.matchup?.name}</div>
              {!!state.matchup && (
                <div className={style.matchupTime}>
                  {Utility.date.toShortDateAndTimeUTC(
                    new Date(state.matchup.eventStartTime),
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!!state.matchup && (
        <div className={style.majorSection}>
          <div className={style.minorSection}>
            <div className={style.optionLabel}>Contest Details</div>
            <Components.DateTimePicker
              wrapperClassName={style.input}
              className={style.textInput}
              value={state.availableStartsAtUtc}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_SWIPE_FIELD",
                  payload: {
                    field: "availableStartsAtUtc",
                    value: e,
                  },
                })
              }
              label="Swipe Contest Available Start Time"
            />
            <Components.DateTimePicker
              wrapperClassName={style.input}
              className={style.textInput}
              value={state.availableEndsAtUtc}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_SWIPE_FIELD",
                  payload: {
                    field: "availableEndsAtUtc",
                    value: e,
                  },
                })
              }
              label="Swipe Contest Available End Time"
            />
            <div className={style.input}>
              <input
                type="file"
                name="image"
                onChange={(e) => setSelectedFile(e!.currentTarget!.files![0])}
                accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
              />
            </div>
            <div className={style.imageContainer}>
              {!!state.imageInB64 ? (
                <img
                  src={state.imageInB64}
                  alt="prop image"
                  className={style.image}
                />
              ) : (
                "no image"
              )}
            </div>
            <div
              onClick={() => {
                setSelectedFile(null);
                dispatch({
                  type: "UPDATE_SWIPE_FIELD",
                  payload: {
                    field: "imageInB64",
                    value: "",
                  },
                });
              }}
              className={style.removeImageButton}
            >
              Remove image
            </div>
          </div>
          {swipeOptionsArr.map((swipeOption, i) => {
            return (
              <div
                className={style.minorSection}
                key={swipeOption.matchupOptionId}
              >
                <div className={style.optionLabel}>
                  {swipeOption.displayName}
                </div>
                <Components.TextInput
                  className={style.textInput}
                  value={swipeOption.displayName}
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_SWIPE_OPTION_FIELD",
                      payload: {
                        optionId: swipeOption.matchupOptionId,
                        field: "displayName",
                        value: e,
                      },
                    })
                  }
                  label="Display Name"
                />
                <Components.TextInput
                  className={style.textInput}
                  value={swipeOption.description}
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_SWIPE_OPTION_FIELD",
                      payload: {
                        optionId: swipeOption.matchupOptionId,
                        field: "description",
                        value: e,
                      },
                    })
                  }
                  label="Description"
                />
                <div className={style.colorRow}>
                  <HexAlphaColorPicker
                    className={style.colorBox}
                    color={swipeOption.backgroundColor}
                    onChange={(e) =>
                      dispatch({
                        type: "UPDATE_SWIPE_OPTION_FIELD",
                        payload: {
                          optionId: swipeOption.matchupOptionId,
                          field: "backgroundColor",
                          value: e,
                        },
                      })
                    }
                    prefix="#"
                  />
                  <HexColorInput
                    color={swipeOption.backgroundColor}
                    onChange={(e) =>
                      dispatch({
                        type: "UPDATE_SWIPE_OPTION_FIELD",
                        payload: {
                          optionId: swipeOption.matchupOptionId,
                          field: "backgroundColor",
                          value: e,
                        },
                      })
                    }
                    alpha
                  />
                  <div>Background Color</div>
                </div>
                <div className={style.input}>
                  <input
                    type="file"
                    name="image"
                    onChange={(e) =>
                      i === 0
                        ? setSelectedFileOption1(e!.currentTarget!.files![0])
                        : setSelectedFileOption2(e!.currentTarget!.files![0])
                    }
                    accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                  />
                </div>
                <div className={style.imageContainer}>
                  {!!swipeOption.imageInB64 || !!swipeOption.imageUrl ? (
                    <img
                      src={swipeOption.imageUrl ?? swipeOption.imageInB64}
                      alt="prop image"
                      className={style.image}
                    />
                  ) : (
                    "no image"
                  )}
                </div>
                <div
                  onClick={() => {
                    if (i === 0) {
                      setSelectedFileOption1(null);
                    } else {
                      setSelectedFileOption2(null);
                    }
                    dispatch({
                      type: "UPDATE_SWIPE_OPTION_FIELD",
                      payload: {
                        optionId: swipeOption.matchupOptionId,
                        field: "imageInB64",
                        value: "",
                      },
                    });
                  }}
                  className={style.removeImageButton}
                >
                  Remove image
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
