import { gql } from "@apollo/client";

export const GET_SURVEYS_QUERY = gql`
  query adminGetSurveys($take: Int!, $skip: Int!) {
    adminGetSurveys(take: $take, skip: $skip) {
      id
      name
      description
      availableStartsAtUtc
      availableEndsAtUtc
      maximumResponses
      rewardAmount
      sponsorId
      status
      sponsor {
        name
      }
      surveyQuestions {
        id
        question
        description
        questionType
        scaleMin
        scaleMax
        sortOrder
        surveyQuestionOptions {
          name
          sortOrder
        }
      }
      _count {
        responses
      }
    }
  }
`;
