import React from "react";
import * as style from "../CreateSwipes/CreateSwipes.style";
import * as Components from "../../../components";
import * as Models from "../../../models";
import * as Utility from "../../../utility";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation } from "../../../gql";
import { toast } from "react-toastify";
import { MenuItem, Select } from "@mui/material";

interface ComponentProps {
  swipeContest: Models.Contests.SwipeContest;
  onUpdateSuccess: () => void;
}

export const UpdateSwipes: React.FC<ComponentProps> = ({
  swipeContest,
  onUpdateSuccess,
}) => {
  const client = useApolloClient();

  const [availableStartsAtUtc, setAvailableStartsAtUtc] =
    React.useState<Date | null>(swipeContest.availableStartsAtUtc);
  const [availableEndsAtUtc, setAvailableEndsAtUtc] =
    React.useState<Date | null>(swipeContest.availableEndsAtUtc);
  const [status, setStatus] =
    React.useState<Models.Contests.SwipeContestStatus>(swipeContest.status);

  const [inProgress, setInProgress] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  const [updateSwipeContestMutation, updateSwipeContestMutationData] =
    useMutation(Mutation.UPDATE_SWIPE_CONTEST_MUTATION);

  const onSubmit = () => {
    let canSubmit = true;

    if (canSubmit) {
      setInProgress(true);
      setSubmitError("");
      let variables: any = {
        swipeContestId: swipeContest.id,
        status,
        availableStartsAtUtc,
        availableEndsAtUtc,
      };

      updateSwipeContestMutation({
        variables,
      })
        .then((res) => {
          if (!!res && !!res.data && !!res.data.adminUpdateSwipeContest) {
            setInProgress(false);

            setAvailableEndsAtUtc(null);
            setAvailableStartsAtUtc(null);
            toast.success("Swipe Contest Updated");
            onUpdateSuccess();
          } else if (res.errors?.length) {
            setInProgress(false);
            toast.error(`Unable to update swipe contest: ${res.errors[0]}`);
          }
        })
        .catch((e) => {
          setInProgress(false);
          toast.error(
            `Unable to update swipe contest : ${
              JSON.parse(JSON.stringify(e)).message
            }`,
          );
          console.log(e);
        });
    }
  };

  React.useEffect(() => {
    setAvailableStartsAtUtc(swipeContest.availableStartsAtUtc);
    setAvailableEndsAtUtc(swipeContest.availableEndsAtUtc);
    setStatus(swipeContest.status);
  }, [swipeContest]);

  return (
    <div className={style.component}>
      <div style={{ marginBottom: 16 }}>
        <Select
          multiple={false}
          value={status}
          onChange={(e) =>
            setStatus(e.target.value as Models.Contests.SwipeContestStatus)
          }
          autoWidth={true}
          label="Status"
        >
          {Object.values(Models.Contests.SwipeContestStatus).map((target) => (
            <MenuItem value={target} key={target}>
              {target}
            </MenuItem>
          ))}
        </Select>
      </div>

      <Components.DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={availableStartsAtUtc}
        onChange={(date) => {
          setAvailableStartsAtUtc(date);
        }}
        label="Bulletin is in app starting at"
        disabled={
          swipeContest.status === Models.Contests.SwipeContestStatus.PUBLISHED
        }
      />
      <Components.DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={availableEndsAtUtc}
        onChange={(date) => setAvailableEndsAtUtc(date)}
        label="Bulletin is in app ending at"
        disabled={
          swipeContest.status === Models.Contests.SwipeContestStatus.PUBLISHED
        }
      />
      <Components.Button
        className={style.button}
        onClick={onSubmit}
        label="Update"
        inProgress={inProgress}
      />
      <div className={style.error}>{submitError}</div>

      <div className={style.previewContainer}>
        <div className={style.previewOptionsContainer}>
          {!!swipeContest.imageUrl && (
            <div className={style.previewMatchupImageContainer}>
              <img src={swipeContest.imageUrl} className={style.previewImage} />
            </div>
          )}
          {[...swipeContest.swipeOptions]
            .sort((a, b) => (a.sortOrder < b.sortOrder ? 1 : -1))
            .map((swipeOption, i) => (
              <div
                className={style.previewOption}
                style={
                  !!swipeOption.backgroundColor
                    ? { backgroundColor: swipeOption.backgroundColor }
                    : {}
                }
                key={swipeOption.matchupOptionId}
              >
                <div className={style.previewOptionImageSection}>
                  {!swipeContest.imageUrl && !!swipeOption.imageUrl ? (
                    <img
                      className={style.previewImage}
                      src={swipeOption.imageUrl}
                    />
                  ) : (
                    <div className={style.previewImage} />
                  )}
                </div>
                <div className={style.previewOptionNameSection}>
                  {swipeOption.displayName}
                </div>
                <div className={style.previewOptionBottomSection}>
                  <div
                    className={style.previewOptionDescription}
                    style={
                      !swipeOption.description
                        ? { backgroundColor: "transparent" }
                        : {}
                    }
                  >
                    {swipeOption.description}
                  </div>
                  <div className={style.previewOptionSwipeButton}>
                    {i === 0 ? "< Swipe" : "Swipe >"}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className={style.matchupName}>{swipeContest.matchup.name}</div>
        {!!swipeContest.matchup && (
          <div className={style.matchupTime}>
            {Utility.date.toShortDateAndTimeUTC(
              new Date(swipeContest.matchup.eventStartTime),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
