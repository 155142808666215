import { gql } from "@apollo/client";

export const UPDATE_SURVEY_MUTATION = gql`
  mutation adminUpdateSurvey(
    $surveyId: Int!
    $status: SurveyStatus!
    $name: String!
    $description: String
    $availableStartsAtUtc: DateTime!
    $availableEndsAtUtc: DateTime!
    $maximumResponses: Int
    $rewardAmount: Int!
    $sponsorId: Int
    $questionsToRemove: [Int!]!
    $questionsToAdd: [CreateSurveyQuestionArgs!]!
  ) {
    adminUpdateSurvey(
      surveyId: $surveyId
      status: $status
      name: $name
      description: $description
      availableStartsAtUtc: $availableStartsAtUtc
      availableEndsAtUtc: $availableEndsAtUtc
      maximumResponses: $maximumResponses
      rewardAmount: $rewardAmount
      sponsorId: $sponsorId
      questionsToRemove: $questionsToRemove
      questionsToAdd: $questionsToAdd
    ) {
      id
      createdAtUtc
      updatedAtUtc
      adminUpdatedBy
      name
      description
      status
      availableStartsAtUtc
      availableEndsAtUtc
    }
  }
`;
