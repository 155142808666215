import * as Models from "../../models";
import { UserActions } from "../actions";

export interface UserStore {
  users: {
    items: Record<string, Models.Users.ShakeUser> | null;
  };
}

export const initialState: UserStore = {
  users: {
    items: null,
  },
};

export const users = (
  state: UserStore = initialState,
  action: UserActions.Actions,
): UserStore => {
  switch (action.type) {
    case UserActions.ActionTypes.GET_USERS_SUCCESS: {
      const newDict: Record<string, Models.Users.ShakeUser> = state.users.items
        ? { ...state.users.items }
        : {};
      action.payload.forEach((user) => {
        newDict[user.id] = user;
      });
      return {
        ...state,
        users: {
          items: newDict,
        },
      };
    }
    case UserActions.ActionTypes.UPDATE_USER_SUCCESS: {
      const newDict: Record<string, Models.Users.ShakeUser> = state.users.items
        ? { ...state.users.items }
        : {};
      newDict[action.payload.id] = {
        ...newDict[action.payload.id],
        ...action.payload,
      };
      return {
        ...state,
        users: {
          ...state.users,
          items: newDict,
        },
      };
    }
    case UserActions.ActionTypes.DELETE_USER_SUCCESS: {
      const newDict: Record<string, Models.Users.ShakeUser> = state.users.items
        ? { ...state.users.items }
        : {};
      delete newDict[action.payload];
      return {
        ...state,
        users: {
          ...state.users,
          items: newDict,
        },
      };
    }
    default:
      return state;
  }
};
