import React from "react";
import * as style from "./Swipes.style";
import { classes } from "typestyle";
import { CreateSwipes } from "./CreateSwipes";
import { ViewSwipes } from "./ViewSwipes";

export const Swipes = () => {
  const [activeTab, setActiveTab] = React.useState("Create");
  return (
    <div className={style.component}>
      <div className={style.title}>{`Swipes`}</div>
      <div className={style.topNav}>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "Create" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("Create")}
        >
          Create Swipe Contests
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "View" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("View")}
        >
          View Swipe Contests
        </div>
      </div>
      {activeTab == "Create" && <CreateSwipes />}
      {activeTab == "View" && <ViewSwipes />}
    </div>
  );
};
