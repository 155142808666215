import * as Styles from "../../../styles";
import { style as s } from "typestyle";

export const component = s({});

export const textInput = s({ width: "100%", marginBottom: 16 });

export const error = s({
  color: Styles.colors.error,
  fontSize: 14,
});

export const input = s({
  display: "flex",
  gap: 12,
});

export const imageContainer = s({
  height: "200px",
  margin: "16px 0px",
});

export const image = s({
  height: "100%",
});

export const button = s({
  margin: "auto",
  marginBottom: 12,
});

export const removeImageButton = s({
  padding: `4px 8px`,
  fontSize: 12,
  width: "fit-content",
  textAlign: "center",
  background: Styles.colors.darkTranslucent650,
  color: Styles.text.colors.inverse,
  cursor: "pointer",
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.darkTranslucent500,
    },
  },
  marginBottom: 8,
  borderRadius: 4,
  fontWeight: 500,
});
