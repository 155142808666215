import * as Styles from "../../styles";
import { style as s } from "typestyle";

export const component = s({
  position: "relative",
});

export const table = s({
  border: "1px solid black",
  margin: "16px 30px 16px 0px",
});

export const tableRow = s({
  width: "100%",
  display: "flex",
  alignItems: "center",
});

export const row = s({
  display: "flex",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: Styles.colors.darkTranslucent100,
    },
  },
});

export const rowItem = s({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  padding: "4px 0px",
});

export const imageContainer = s({
  height: "40px",
});

export const image = s({
  height: "100%",
});

export const selectedRow = s({
  border: "1px solid yellow",
});

export const subTitle = s({
  textAlign: "center",
  fontSize: 32,
  fontWeight: 500,
});

export const selectedUser = s({
  border: "1px solid black",
});

export const searchContainer = s({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  gap: 8,
  margin: 24,
});

export const dropdown = s({
  marginBottom: 24,
});

export const wrapperTextInput = s({
  flex: 2,
});

export const textInput = s({ width: "100%", marginBottom: 16 });

export const searchButton = s({
  marginBottom: 24,
});

export const resetSearch = s({
  backgroundColor: Styles.colors.error,
});

export const error = s({
  color: Styles.colors.error,
  fontSize: 14,
});

export const input = s({
  display: "flex",
  gap: 12,
  alignItems: "center",
});

export const modalBackground = s({
  position: "fixed",
  inset: 0,
  backgroundColor: Styles.colors.darkTranslucent750,
  zIndex: 2,
  padding: 72,
});

export const modalContainer = s({
  backgroundColor: "white",
  padding: 24,
  zIndex: 3,
});

export const modalTitle = s({
  fontSize: 48,
  textAlign: "center",
  marginBottom: 12,
});

export const textContent = s({
  fontSize: 24,
  fontWeight: 700,
  textAlign: "center",
  marginBottom: 24,
  padding: 8,
  backgroundColor: Styles.colors.softYellow,
});

export const button = s({
  marginBottom: 12,
});
