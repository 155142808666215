import { style as s } from "typestyle";
import * as Style from "../../styles";

export const wrapper = s({
  position: "relative",
});

export const component = s({
  backgroundColor: Style.colors.translucent150,
  height: 50,
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
  padding: "0px 25px",
  fontFamily: Style.text.font.main,
  boxSizing: "border-box",
  border: `1px solid ${Style.colors.darkTranslucent750}`,
  transition: ".2s ease-in",
  fontSize: 16,
  color: Style.text.colors.main,
  outline: "none",
  resize: "none",
  zIndex: 2,
  fontWeight: 400,
});

export const active = s({
  borderColor: Style.colors.darkTranslucent650,
  backgroundColor: Style.colors.translucent400,
  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
});

export const hasError = s({
  borderColor: Style.colors.error,
});

export const label = s({
  color: Style.colors.darkTranslucent500,
  fontSize: 14,
  position: "absolute",
  left: 25,
  top: "calc(50% - 9px)",
  zIndex: 1,
  transition: ".2s ease-in",
  textAlign: "left",
});

export const filledLabel = s({
  fontSize: 9,
  top: 4,
  left: 4,
});

export const error = s({
  paddingTop: 5,
  color: Style.colors.error,
  textAlign: "left",
  marginBottom: 8,
  fontSize: 12,
});

export const disabled = s({
  color: Style.colors.darkTranslucent250,
  borderColor: Style.colors.darkTranslucent250,
});
