import React from "react";
import * as style from "./ViewContestTemplates.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";
import { connect, useDispatch } from "react-redux";
import { ContestActions } from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import { ContestsStoreContestTemplate } from "../../../store/reducers/contests";
import { classes } from "typestyle";
import * as utility from "../../../utility";
import * as Components from "../../../components";
import { UpdateTemplate } from "./UpdateTemplate";
import { ContestTemplateStatus } from "../../../models/contest";
import { UpdateBucket } from "./UpdateBucket";

interface ComponentProps {
  contestTemplates: Record<number, ContestsStoreContestTemplate> | null;
  isOpen: boolean;
}

export const ViewContestTemplatesInternal: React.FC<ComponentProps> = ({
  contestTemplates,
  isOpen,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<
    number | null
  >(null);
  const [selectedUpdateTab, setSelectedUpdateTab] = React.useState<
    number | null
  >(null);
  const [canFetchMore, setCanFetchMore] = React.useState(true);

  const templatesArray = React.useMemo(() => {
    return !!contestTemplates ? Object.values(contestTemplates) : [];
  }, [contestTemplates]);

  const getContestTemplates = (skip?: number) => {
    client
      .query({
        query: Query.GET_CONTEST_TEMPLATES_QUERY,
        fetchPolicy: "no-cache",
        variables: {
          take: 30,
          skip: skip ?? 0,
          statuses: [
            ContestTemplateStatus.DRAFT,
            ContestTemplateStatus.PUBLISHED,
            ContestTemplateStatus.DISABLED,
            ContestTemplateStatus.COMPLETED,
          ],
        },
      })
      .then((res) => {
        if (res?.data?.adminContestTemplates?.length) {
          dispatch(
            ContestActions.getContestTemplatesSuccess({
              contestTemplates: res.data.adminContestTemplates,
            }),
          );
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    if (isOpen) {
      getContestTemplates();
    }
  }, [isOpen]);

  const selectedTemplate =
    !!selectedTemplateId && !!contestTemplates
      ? contestTemplates[selectedTemplateId]
      : null;

  React.useEffect(() => {
    if (selectedTemplate) {
      setSelectedUpdateTab(-1);
    }
  }, [selectedTemplate]);

  return (
    <div className={style.main}>
      {!!templatesArray ? (
        <>
          <div className={style.table}>
            <div className={style.row} style={{ fontWeight: 600 }}>
              <div className={style.rowItem}>Id</div>
              <div className={style.rowItem} style={{ flex: 2 }}>
                Name
              </div>
              <div className={style.rowItem}>Available</div>
              <div className={style.rowItem}>Format</div>
              <div className={style.rowItem}>Entry</div>
              <div className={style.rowItem}>Status</div>
            </div>
            {templatesArray.map((template) => (
              <div
                className={classes(
                  style.row,
                  template.id === selectedTemplateId ? style.selectedRow : "",
                )}
                key={template.id}
                onClick={() => {
                  setSelectedTemplateId(template.id);
                }}
              >
                <div className={style.rowItem}>{template.id}</div>
                <div className={style.rowItem} style={{ flex: 2 }}>
                  {template.name}
                </div>
                <div
                  className={style.rowItem}
                >{`${utility.date.toShortDateAndTimeUTC(
                  new Date(template.availableStartsAtUtc),
                )} - ${utility.date.toShortDateAndTimeUTC(
                  new Date(template.availableEndsAtUtc),
                )}`}</div>
                <div className={style.rowItem}>
                  {`${
                    template.minContestants > 2
                      ? "Group"
                      : template.maxContestants < 3
                      ? "1v1"
                      : "1v1 OR Group"
                  }`}
                </div>
                <div className={style.rowItem}>{`${
                  !template.freeEntry
                    ? "Token"
                    : !template.tokenEntry
                    ? "Free"
                    : "Free or Token"
                }`}</div>
                <div className={style.rowItem}>
                  {template.status.toString()}
                </div>
              </div>
            ))}
          </div>
          <Components.Button
            onClick={() => {
              getContestTemplates(templatesArray.length);
            }}
            label="Fetch More"
          />
        </>
      ) : (
        <Components.Icon.Spinner size={48} />
      )}
      {selectedTemplate && (
        <>
          <div className={style.updateTabs}>
            <div
              className={classes(
                style.updateTab,
                selectedUpdateTab === -1 ? style.selectedUpdateTab : "",
              )}
              onClick={() => setSelectedUpdateTab(-1)}
            >
              Template
            </div>
            {selectedTemplate.buckets.map((bucket) => (
              <div
                className={classes(
                  style.updateTab,
                  selectedUpdateTab === bucket.id
                    ? style.selectedUpdateTab
                    : "",
                )}
                key={bucket.id}
                onClick={() => setSelectedUpdateTab(bucket.id)}
              >
                {bucket.name}
              </div>
            ))}
          </div>
        </>
      )}
      {!!selectedTemplateId && selectedUpdateTab === -1 && (
        <UpdateTemplate
          contestTemplateId={selectedTemplateId}
          onSuccess={() => setSelectedTemplateId(null)}
        />
      )}
      {!!selectedTemplateId &&
        !!selectedUpdateTab &&
        selectedUpdateTab !== -1 && (
          <UpdateBucket
            contestTemplateId={selectedTemplateId}
            onSuccess={() => setSelectedUpdateTab(-1)}
            bucketId={selectedUpdateTab}
          />
        )}
    </div>
  );
};

export const ViewContestTemplates = connect((state: RootState) => ({
  contestTemplates: state.contests.contestTemplates,
}))(ViewContestTemplatesInternal);
