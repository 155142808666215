import React from "react";
import * as style from "../CreateSurvey/CreateSurvey.style";
import * as Components from "../../../components";
import * as Models from "../../../models";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import { toast } from "react-toastify";
import { Checkbox, MenuItem, Select } from "@mui/material";
import {
  updateSurveyReducer,
  initialUpdateSurveyState,
} from "./updateSurvey.reducer";
import { RewardsStoreBrand } from "../../../store/reducers/rewards";
import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import { RewardActions } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { classes, stylesheet } from "typestyle";
import { questionTypes } from "../CreateSurvey/CreateSurvey";

interface StateProps {
  brands: Record<number, RewardsStoreBrand> | null;
}

interface ComponentProps {
  survey: Models.Promo.Survey;
  onClose: () => void;
}

export const UpdateSurveyInternal: React.FC<StateProps & ComponentProps> = ({
  brands,
  survey,
  onClose,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [newQuestionOption, setNewQuestionOption] = React.useState("");
  const [inProgress, setInProgress] = React.useState(false);

  const [state, localDispatch] = React.useReducer(
    updateSurveyReducer,
    initialUpdateSurveyState,
  );

  const questionsArray = React.useMemo(() => {
    return Object.keys(state.surveyQuestions).map((k) => {
      const idx = Number.parseInt(k);
      return {
        idx,
        ...state.surveyQuestions[idx],
      };
    });
  }, [state.surveyQuestions]);

  const currentQuestion = React.useMemo(() => {
    return state.surveyQuestions[state.currentQuestionIndex];
  }, [state.surveyQuestions, state.currentQuestionIndex]);

  const brandsArray = React.useMemo(() => {
    return !!brands ? Object.values(brands) : [];
  }, [brands]);

  const getBrands = (skip?: number) => {
    client
      .query({
        query: Query.GET_BRANDS_QUERY,
        variables: {
          skip: skip ?? 0,
          take: 50,
        },
      })
      .then((res) => {
        dispatch(RewardActions.GetBrandsSuccess(res.data.adminGetBrands));
      });
  };

  React.useEffect(() => {
    getBrands();
  }, []);

  React.useEffect(() => {
    localDispatch({ type: "PARSE_EXISTING_SURVEY", payload: survey });
  }, [survey]);

  const [updateSurveyMutation, updateSurveyMutationData] = useMutation(
    Mutation.UPDATE_SURVEY_MUTATION,
  );

  React.useEffect(() => {
    if (!!state.readyToSubmit) {
      const questionsToRemove: number[] = [];
      const questionsToAdd: Models.Promo.SurveyQuestionCreateArgs[] = [];
      survey.surveyQuestions.forEach((q) => {
        if (!state.surveyQuestions[q.id]) {
          questionsToRemove.push(q.id);
        }
      });
      Object.keys(state.surveyQuestions).forEach((k) => {
        if (!survey.surveyQuestions.some((q) => q.id.toString() === k)) {
          const q = state.surveyQuestions[Number.parseInt(k)];
          questionsToAdd.push({
            question: q.question,
            description: q.description,
            questionType: q.questionType,
            sortOrder: q.sortOrder,
            scaleMin: !!q.scaleMin ? q.scaleMin : undefined,
            scaleMax: !!q.scaleMax ? q.scaleMax : undefined,
            surveyQuestionOptions: q.surveyQuestionOptions.map((opt, i) => ({
              name: opt,
              sortOrder: i + 1,
            })),
          });
        }
      });
      setInProgress(true);
      updateSurveyMutation({
        variables: {
          surveyId: survey.id,
          status: state.status,
          name: state.name,
          description: state.description,
          availableStartsAtUtc: state.availableStartsAtUtc,
          availableEndsAtUtc: state.availableEndsAtUtc,
          maximumResponses: state.maximumResponses,
          rewardAmount: state.rewardAmount,
          sponsorId: state.sponsorId,
          questionsToRemove,
          questionsToAdd,
        },
      })
        .then((res) => {
          setInProgress(false);
          if (!!res.data.adminUpdateSurvey) {
            toast.success("Successfull updated survey");
            onClose();
          } else {
            toast.error("Something went wrong, sorry");
          }
        })
        .catch((e) => {
          setInProgress(false);
          toast.error("Something went wrong, sorry");
          console.log(JSON.stringify(e));
        });
    }
  }, [state.readyToSubmit]);

  const isDraft = survey.status === Models.Promo.SurveyStatus.DRAFT;

  return (
    <div className={style.component}>
      <div className={style.selectContainer}>
        <Select
          multiple={false}
          value={state.status}
          onChange={(e) =>
            localDispatch({
              type: "UPDATE_SURVEY_FIELD",
              payload: {
                field: "status",
                value: e.target.value,
              },
            })
          }
          autoWidth={true}
        >
          {Object.values(Models.Promo.SurveyStatus).map((target) => (
            <MenuItem value={target} key={target}>
              {target}
            </MenuItem>
          ))}
        </Select>
        <div>Status</div>
      </div>
      <Components.TextInput
        className={style.textInput}
        value={state.name}
        onChange={(value) => {
          localDispatch({
            type: "UPDATE_SURVEY_FIELD",
            payload: {
              field: "name",
              value,
            },
          });
        }}
        label="Survey Name"
        autocomplete="off"
        disabled={!isDraft}
      />
      <Components.TextInput
        className={style.textInput}
        value={state.description}
        onChange={(value) => {
          localDispatch({
            type: "UPDATE_SURVEY_FIELD",
            payload: {
              field: "description",
              value,
            },
          });
        }}
        label="Description (Optional)"
        autocomplete="off"
        disabled={!isDraft}
      />
      <Components.DateTimePicker
        wrapperClassName={style.input}
        className={style.textInput}
        value={state.availableStartsAtUtc}
        onChange={(e) =>
          localDispatch({
            type: "UPDATE_SURVEY_FIELD",
            payload: {
              field: "availableStartsAtUtc",
              value: e,
            },
          })
        }
        label="Survey Window Start Time"
        disabled={!isDraft}
      />
      <Components.DateTimePicker
        wrapperClassName={style.input}
        className={style.textInput}
        value={state.availableEndsAtUtc}
        onChange={(e) =>
          localDispatch({
            type: "UPDATE_SURVEY_FIELD",
            payload: {
              field: "availableEndsAtUtc",
              value: e,
            },
          })
        }
        label="Survey Window Close Time"
        disabled={!isDraft}
      />
      <Components.TextInput
        className={style.textInput}
        value={
          !!state.maximumResponses ? state.maximumResponses.toString() : ""
        }
        onChange={(e) =>
          localDispatch({
            type: "UPDATE_SURVEY_FIELD",
            payload: {
              field: "maximumResponses",
              value: Number.parseInt(e),
            },
          })
        }
        label="Maximum Responses (Optional)"
        inputType="number"
        disabled={!isDraft}
      />
      <Components.TextInput
        className={style.textInput}
        value={state.rewardAmount.toString()}
        onChange={(e) =>
          localDispatch({
            type: "UPDATE_SURVEY_FIELD",
            payload: {
              field: "rewardAmount",
              value: Number.parseInt(e),
            },
          })
        }
        label="Reward Amount"
        inputType="number"
        disabled={!isDraft}
      />
      <div className={style.selectContainer}>
        <Select
          multiple={false}
          value={state.sponsorId ?? ""}
          onChange={(e) =>
            localDispatch({
              type: "UPDATE_SURVEY_FIELD",
              payload: {
                field: "sponsorId",
                value: e.target.value,
              },
            })
          }
          autoWidth={true}
          label="Sponsor"
          disabled={!isDraft}
        >
          {brandsArray.map((brand) => (
            <MenuItem value={brand.id} key={brand.id}>
              {brand.name}
            </MenuItem>
          ))}
        </Select>
        <div>Sponsor (Optional)</div>
        {!!isDraft && (
          <div
            onClick={() =>
              localDispatch({
                type: "UPDATE_SURVEY_FIELD",
                payload: {
                  field: "sponsorId",
                  value: null,
                },
              })
            }
            className={style.clearButton}
          >
            Clear
          </div>
        )}
      </div>
      <div className={style.smallHeader}>Questions</div>
      <div className={style.questionsContainer}>
        <div className={style.questionsSection} style={{ flex: 1 }}>
          {questionsArray.map((q) => (
            <div className={style.questionContainer} key={q.idx}>
              <div
                className={classes(
                  style.question,
                  state.currentQuestionIndex === q.idx
                    ? style.selectedQuestion
                    : "",
                )}
                onClick={() => {
                  localDispatch({
                    type: "UPDATE_CURRENT_QUESTION_INDEX",
                    payload: {
                      newIndex: q.idx,
                    },
                  });
                }}
              >
                {q.question}
              </div>
              {!!isDraft && questionsArray.length > 1 && (
                <Components.Icon.Close
                  onClick={() =>
                    localDispatch({
                      type: "DELETE_QUESTION",
                      payload: { questionIndex: q.idx },
                    })
                  }
                  size={16}
                  stroke="red"
                />
              )}
            </div>
          ))}
          <div
            className={style.clearButton}
            onClick={() => {
              localDispatch({
                type: "CREATE_QUESTION",
              });
            }}
          >
            Add Question
          </div>
        </div>
        <div className={style.questionsSection} style={{ flex: 3 }}>
          <Components.TextInput
            className={style.textInput}
            value={currentQuestion.question}
            onChange={(value) => {
              localDispatch({
                type: "UPDATE_SURVEY_QUESTION_FIELD",
                payload: {
                  field: "question",
                  value,
                },
              });
            }}
            label="Question"
            disabled={!isDraft || currentQuestion.isExisting}
          />
          <Components.TextInput
            className={style.textInput}
            value={currentQuestion.description}
            onChange={(value) => {
              localDispatch({
                type: "UPDATE_SURVEY_QUESTION_FIELD",
                payload: {
                  field: "description",
                  value,
                },
              });
            }}
            label="Description (Optional)"
            disabled={!isDraft || currentQuestion.isExisting}
          />
          <div className={style.selectContainer}>
            <Select
              multiple={false}
              value={currentQuestion.questionType ?? ""}
              onChange={(e) =>
                localDispatch({
                  type: "UPDATE_SURVEY_QUESTION_FIELD",
                  payload: {
                    field: "questionType",
                    value: e.target.value,
                  },
                })
              }
              autoWidth={true}
              disabled={!isDraft || currentQuestion.isExisting}
            >
              {Object.values(Models.Promo.SurveyQuestionType).map((target) => (
                <MenuItem value={target} key={target}>
                  {questionTypes[target]}
                </MenuItem>
              ))}
            </Select>
            <div>Question Type</div>
          </div>
          {currentQuestion.questionType ===
            Models.Promo.SurveyQuestionType.RATING_SCALE && (
            <>
              <Components.TextInput
                className={style.textInput}
                value={
                  !!currentQuestion.scaleMin
                    ? currentQuestion.scaleMin.toString()
                    : ""
                }
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_SURVEY_QUESTION_FIELD",
                    payload: {
                      field: "scaleMin",
                      value: Number.parseInt(e),
                    },
                  })
                }
                label="Scale minimum"
                inputType="number"
                disabled={!isDraft || currentQuestion.isExisting}
              />
              <Components.TextInput
                className={style.textInput}
                value={
                  !!currentQuestion.scaleMax
                    ? currentQuestion.scaleMax.toString()
                    : ""
                }
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_SURVEY_QUESTION_FIELD",
                    payload: {
                      field: "scaleMax",
                      value: Number.parseInt(e),
                    },
                  })
                }
                label="Scale maximum"
                inputType="number"
                disabled={!isDraft || currentQuestion.isExisting}
              />
            </>
          )}
          {(currentQuestion.questionType ===
            Models.Promo.SurveyQuestionType.RADIO_MULTIPLE ||
            currentQuestion.questionType ===
              Models.Promo.SurveyQuestionType.RADIO_SINGLE) && (
            <>
              <div className={style.questionOption}>
                <div className={style.xsHeader}>Options</div>
                <Components.TextInput
                  value={newQuestionOption}
                  onChange={setNewQuestionOption}
                  label="New Question Option"
                  disabled={!isDraft || currentQuestion.isExisting}
                />
                <Components.Button
                  onClick={() => {
                    localDispatch({
                      type: "ADD_QUESTION_OPTION",
                      payload: {
                        value: newQuestionOption,
                      },
                    });
                    setNewQuestionOption("");
                  }}
                  label="Add"
                  disabled={
                    !isDraft ||
                    !!currentQuestion.isExisting ||
                    !newQuestionOption ||
                    currentQuestion.surveyQuestionOptions.includes(
                      newQuestionOption,
                    )
                  }
                />
              </div>
              <ul>
                {currentQuestion.surveyQuestionOptions.map((opt, i) => (
                  <li>
                    <div className={style.questionOption} key={opt}>
                      <div>{opt}</div>
                      {!!isDraft && !currentQuestion.isExisting && (
                        <Components.Icon.Close
                          onClick={() => {
                            localDispatch({
                              type: "REMOVE_QUESTION_OPTION",
                              payload: { questionOptionIndex: i },
                            });
                          }}
                          size={12}
                          stroke="red"
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
      <Components.Button
        className={style.button}
        onClick={() => localDispatch({ type: "SUBMIT" })}
        label="Submit"
        inProgress={inProgress}
      />
      <div className={style.error}>{state.submitError}</div>
    </div>
  );
};

export const UpdateSurvey = connect((state: RootState) => ({
  brands: state.rewards.brands.items,
}))(UpdateSurveyInternal);
