import React from "react";
import * as style from "./Users.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../models";
import * as Components from "../../components";
import { UserActions } from "../../store/actions";
import { classes } from "typestyle";
import { RootState } from "../../store/reducers";
import { Checkbox, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

interface StateProps {
  user: Models.Users.ShakeUser;
  onClose: () => void;
}

export const AddTokensModal: React.FC<StateProps> = ({ user, onClose }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [guid, setGuid] = React.useState(uuidv4());
  const [addTokensAmount, setAddTokensAmount] = React.useState("");
  const [submitError, setSubmitError] = React.useState("");

  const [addTransaction, addTransactionStatus] = useMutation(
    Mutation.ADD_WALLET_TRANSACTION_MUTATION,
  );

  const onSubmit = () => {
    addTransaction({
      variables: {
        userId: user.id,
        amount: Number.parseInt(addTokensAmount),
        refId: guid,
        currencyType: "SHAKE_TOKEN",
      },
    })
      .then((res) => {
        if (res.data.adminAddWalletTransaction?.success) {
          toast.success("Success");
          onClose();
        } else {
          toast.error(
            "Something went wrong: " +
              res?.data?.adminAddWalletTransaction?.error,
          );
        }
      })
      .catch((e) => {
        toast.error(JSON.parse(JSON.stringify(e)).message);
      });
  };

  return (
    <div className={style.modalBackground}>
      <div className={style.modalContainer}>
        <div className={style.modalTitle}>Add Tokens</div>
        <div className={style.textContent}>{user.displayName}</div>
        <Components.TextInput
          className={style.textInput}
          value={addTokensAmount}
          onChange={setAddTokensAmount}
          label="Amount"
          inputType="number"
        />
        <Components.Button
          label="Add Tokens"
          onClick={onSubmit}
          disabled={!addTokensAmount}
          className={style.button}
        />
        <Components.Button
          label="Close"
          onClick={onClose}
          className={style.button}
        />
      </div>
    </div>
  );
};
