import { gql } from "@apollo/client";

export const CREATE_SURVEY_MUTATION = gql`
  mutation adminCreateSurvey(
    $name: String!
    $description: String
    $availableStartsAtUtc: DateTime!
    $availableEndsAtUtc: DateTime!
    $maximumResponses: Int
    $rewardAmount: Int!
    $sponsorId: Int
    $surveyQuestions: [CreateSurveyQuestionArgs!]!
  ) {
    adminCreateSurvey(
      name: $name
      description: $description
      availableStartsAtUtc: $availableStartsAtUtc
      availableEndsAtUtc: $availableEndsAtUtc
      maximumResponses: $maximumResponses
      rewardAmount: $rewardAmount
      sponsorId: $sponsorId
      surveyQuestions: $surveyQuestions
    ) {
      id
      createdAtUtc
      updatedAtUtc
      adminUpdatedBy
      name
      description
    }
  }
`;
