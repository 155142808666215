import * as Models from "../../../models";
/**
 * State
 */

interface UpdateSurveySurveyQuestion {
  id: number;
  question: string;
  description: string;
  questionType: Models.Promo.SurveyQuestionType;
  scaleMin: number | null;
  scaleMax: number | null;
  sortOrder: number;
  surveyQuestionOptions: string[];
  error: string;
  isExisting: boolean;
}

export interface UpdateSurveyState {
  name: string;
  description: string;
  availableStartsAtUtc: Date;
  availableEndsAtUtc: Date;
  maximumResponses: number | null;
  rewardAmount: number;
  sponsorId: number | null;
  currentQuestionIndex: number;
  surveyQuestions: Record<number, UpdateSurveySurveyQuestion>;
  status: Models.Promo.SurveyStatus;
  submitError: string;
  readyToSubmit: boolean;
}

export const initialUpdateSurveyState: UpdateSurveyState = {
  name: "",
  description: "",
  availableStartsAtUtc: new Date(),
  availableEndsAtUtc: new Date(),
  maximumResponses: null,
  rewardAmount: 200,
  sponsorId: null,
  currentQuestionIndex: 0,
  status: Models.Promo.SurveyStatus.DRAFT,
  surveyQuestions: {
    0: {
      id: 1,
      question: "Question 1",
      description: "",
      questionType: Models.Promo.SurveyQuestionType.OPEN,
      scaleMin: null,
      scaleMax: null,
      sortOrder: 1,
      surveyQuestionOptions: [],
      error: "",
      isExisting: false,
    },
  },
  submitError: "",
  readyToSubmit: false,
};

/**
 * Actions
 */

export interface ParseExistingSurvey {
  type: "PARSE_EXISTING_SURVEY";
  payload: Models.Promo.Survey;
}

export interface UpdateSurvey {
  type: "UPDATE_SURVEY_FIELD";
  payload: {
    field:
      | "name"
      | "description"
      | "availableStartsAtUtc"
      | "availableEndsAtUtc"
      | "maximumResponses"
      | "rewardAmount"
      | "sponsorId"
      | "status";
    value: string | number | Date | null | Models.Promo.SurveyStatus;
  };
}

export interface UpdateQuestion {
  type: "UPDATE_SURVEY_QUESTION_FIELD";
  payload: {
    field:
      | "question"
      | "description"
      | "questionType"
      | "scaleMin"
      | "scaleMax"
      | "sortOrder";
    value: string | number | Models.Promo.SurveyQuestionType | null;
  };
}

export interface AddQuestionOption {
  type: "ADD_QUESTION_OPTION";
  payload: {
    value: string;
  };
}

export interface RemoveQuestionOption {
  type: "REMOVE_QUESTION_OPTION";
  payload: {
    questionOptionIndex: number;
  };
}

export interface UpdateCurrentQuestionIndex {
  type: "UPDATE_CURRENT_QUESTION_INDEX";
  payload: {
    newIndex: number;
  };
}

export interface CreateQuestion {
  type: "CREATE_QUESTION";
}

export interface DeleteQuestion {
  type: "DELETE_QUESTION";
  payload: {
    questionIndex: number;
  };
}

export interface Submit {
  type: "SUBMIT";
}

export type UpdateSurveyAction =
  | ParseExistingSurvey
  | UpdateSurvey
  | UpdateQuestion
  | CreateQuestion
  | DeleteQuestion
  | AddQuestionOption
  | RemoveQuestionOption
  | UpdateCurrentQuestionIndex
  | Submit;

/**
 * Reducer
 */

export const updateSurveyReducer = (
  state: UpdateSurveyState,
  action: UpdateSurveyAction,
) => {
  switch (action.type) {
    case "PARSE_EXISTING_SURVEY": {
      const questionsDictionary: Record<number, UpdateSurveySurveyQuestion> =
        {};
      action.payload.surveyQuestions.forEach((q) => {
        questionsDictionary[q.id] = {
          id: q.id,
          question: q.question,
          description: q.description ?? "",
          questionType: q.questionType,
          scaleMin: q.scaleMin ?? null,
          scaleMax: q.scaleMax ?? null,
          sortOrder: q.sortOrder,
          surveyQuestionOptions: q.surveyQuestionOptions.map((opt) => opt.name),
          error: "",
          isExisting: true,
        };
      });
      return {
        name: action.payload.name,
        description: action.payload.description ?? "",
        availableStartsAtUtc: action.payload.availableStartsAtUtc,
        availableEndsAtUtc: action.payload.availableEndsAtUtc,
        maximumResponses: action.payload.maximumResponses ?? null,
        rewardAmount: action.payload.rewardAmount,
        sponsorId: action.payload.sponsorId ?? null,
        currentQuestionIndex: action.payload.surveyQuestions[0].id,
        surveyQuestions: questionsDictionary,
        status: action.payload.status,
        submitError: "",
        readyToSubmit: false,
      };
    }
    case "UPDATE_SURVEY_FIELD": {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case "UPDATE_SURVEY_QUESTION_FIELD": {
      return {
        ...state,
        surveyQuestions: {
          ...state.surveyQuestions,
          [state.currentQuestionIndex]: {
            ...state.surveyQuestions[state.currentQuestionIndex],
            [action.payload.field]: action.payload.value,
            ...(action.payload.field === "questionType"
              ? action.payload.value ===
                Models.Promo.SurveyQuestionType.RATING_SCALE
                ? { scaleMin: 1, scaleMax: 10 }
                : { scaleMin: null, scaleMax: null }
              : {}),
            ...(action.payload.field === "questionType"
              ? { surveyQuestionOptions: [] }
              : {}),
          },
        },
      };
    }
    case "CREATE_QUESTION": {
      const questionsArray = Object.values(state.surveyQuestions);
      let newQuestionIndex = 10000;
      let currentHighestSortOrder = -1;
      questionsArray.forEach((q) => {
        if (q.sortOrder > currentHighestSortOrder) {
          currentHighestSortOrder = q.sortOrder;
        }
      });
      while (!!state.surveyQuestions[newQuestionIndex]) {
        newQuestionIndex++;
      }
      return {
        ...state,
        surveyQuestions: {
          ...state.surveyQuestions,
          [newQuestionIndex]: {
            id: newQuestionIndex,
            question: `Question ${currentHighestSortOrder + 1}`,
            description: "",
            questionType: Models.Promo.SurveyQuestionType.OPEN,
            scaleMin: null,
            scaleMax: null,
            sortOrder: currentHighestSortOrder + 1,
            surveyQuestionOptions: [],
            error: "",
            isExisting: false,
          },
        },
        currentQuestionIndex: newQuestionIndex,
      };
    }
    case "DELETE_QUESTION": {
      const newQuestions = { ...state.surveyQuestions };
      delete newQuestions[action.payload.questionIndex];
      return {
        ...state,
        surveyQuestions: newQuestions,
        currentQuestionIndex:
          state.currentQuestionIndex === action.payload.questionIndex
            ? Number.parseInt(Object.keys(newQuestions)[0])
            : state.currentQuestionIndex,
      };
    }
    case "ADD_QUESTION_OPTION": {
      return {
        ...state,
        surveyQuestions: {
          ...state.surveyQuestions,
          [state.currentQuestionIndex]: {
            ...state.surveyQuestions[state.currentQuestionIndex],
            surveyQuestionOptions: [
              ...state.surveyQuestions[state.currentQuestionIndex]
                .surveyQuestionOptions,
              action.payload.value,
            ],
          },
        },
      };
    }
    case "REMOVE_QUESTION_OPTION": {
      const newQuestionOptionsArray = [
        ...state.surveyQuestions[state.currentQuestionIndex]
          .surveyQuestionOptions,
      ].splice(action.payload.questionOptionIndex, 1);
      return {
        ...state,
        surveyQuestions: {
          ...state.surveyQuestions,
          [state.currentQuestionIndex]: {
            ...state.surveyQuestions[state.currentQuestionIndex],
            surveyQuestionOptions: newQuestionOptionsArray,
          },
        },
      };
    }
    case "UPDATE_CURRENT_QUESTION_INDEX": {
      return {
        ...state,
        currentQuestionIndex: action.payload.newIndex,
      };
    }
    case "SUBMIT": {
      return {
        ...state,
        readyToSubmit: true,
      };
    }
  }
};
