import React from "react";
import * as style from "./ViewSwipes.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";
import * as Models from "../../../models";
import * as Utility from "../../../utility";
import { Icon } from "../../../components";
import { useDispatch } from "react-redux";
import { classes } from "typestyle";
import { toast } from "react-toastify";
import { UpdateSwipes } from "./UpdateSwipes";

export const ViewSwipes: React.FC = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedSwipeId, setSelectedSwipeId] = React.useState<number | null>(
    null,
  );
  const [swipeContests, setSwipeContests] = React.useState<
    Models.Contests.SwipeContest[]
  >([]);

  const getSwipeContests = (skip?: number) => {
    client
      .query({
        query: Query.GET_SWIPE_CONTESTS_QUERY,
        variables: {
          skip: skip ?? 0,
          take: 20,
        },
      })
      .then((res) => {
        if (res.data.adminSwipeContests) {
          setSwipeContests(res.data.adminSwipeContests);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed to get Swipe Contests");
      });
  };
  React.useEffect(() => {
    getSwipeContests();
  }, []);

  const selectedSwipeContest = React.useMemo(() => {
    return swipeContests.find((b) => b.id === selectedSwipeId);
  }, [selectedSwipeId]);

  return (
    <div className={style.component}>
      {!!swipeContests ? (
        <div className={style.table}>
          {!!swipeContests.length ? (
            swipeContests.map((swipeContest) => (
              <div
                className={classes(
                  style.row,
                  swipeContest.id === selectedSwipeId ? style.selectedRow : "",
                )}
                key={swipeContest.id}
                onClick={() => {
                  setSelectedSwipeId(swipeContest.id);
                }}
              >
                <div className={style.rowItem}>{swipeContest.matchup.name}</div>
                <div className={style.rowItem}>{swipeContest.status}</div>
                <div className={style.rowItem}>
                  <div>
                    <div>
                      {Utility.date.toShortDateAndTimeUTC(
                        new Date(swipeContest.availableStartsAtUtc),
                      )}
                    </div>
                    <div>
                      {Utility.date.toShortDateAndTimeUTC(
                        new Date(swipeContest.availableEndsAtUtc),
                      )}
                    </div>
                  </div>
                </div>
                <div className={style.rowItem}>
                  <div className={style.imageContainer}>
                    <img src={swipeContest.imageUrl} className={style.image} />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              className={classes(style.row, style.rowItem)}
              style={{ fontWeight: 600 }}
            >
              No Swipe Contests To Show
            </div>
          )}
        </div>
      ) : (
        <Icon.Spinner size={24} />
      )}
      {!!selectedSwipeContest && (
        <UpdateSwipes
          swipeContest={selectedSwipeContest}
          onUpdateSuccess={() => {
            setSelectedSwipeId(null);
            getSwipeContests();
          }}
        />
      )}
    </div>
  );
};
