import * as Styles from "../../../styles";
import { style as s } from "typestyle";
export * from "../../Bulletins/CreateBulletins/CreateBulletins.style";

export const majorSection = s({
  display: "flex",
});

export const minorSection = s({
  flex: 1,
  padding: 8,
});

export const matchupsContainer = s({
  border: "1px solid gray",
  borderRadius: 12,
  width: 400,
  padding: "4px 8px",
  height: "fit-content",
  maxHeight: 250,
  overflowY: "scroll",
});

export const matchup = s({
  fontSize: 14,
  fontWeight: 500,
  padding: 4,
  cursor: "pointer",
});

export const selectedMatchup = s({
  width: "100%",
  backgroundColor: Styles.colors.softYellow,
  fontSize: 14,
  fontWeight: 500,
  padding: 4,
  cursor: "pointer",
});

export const previewSection = s({
  flex: 1,
  padding: 8,
  minHeight: 300,
  backgroundColor: Styles.colors.darkTranslucent500,
});

export const previewContainer = s({
  margin: "auto",
  width: 400,
  border: "1px solid black",
  backgroundColor: "white",
  borderRadius: 24,
  padding: "24px 12px",
});

export const previewOptionsContainer = s({
  width: "100%",
  borderRadius: 24,
  display: "flex",
  marginBottom: 24,
  backgroundColor: Styles.colors.black4,
  position: "relative",
});

export const previewMatchupImageContainer = s({
  position: "absolute",
  top: 24,
  left: 0,
  right: 0,
  zIndex: 3,
  textAlign: "center",
});

export const previewOption = s({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "8px",
  position: "relative",
  zIndex: 2,
});

export const previewOptionImageSection = s({
  flex: 2,
  padding: "24px 8px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  objectFit: "cover",
});

export const previewImage = s({
  width: 120,
  height: 120,
});

export const previewOptionNameSection = s({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  color: Styles.colors.white,
  textTransform: "uppercase",
  padding: "36px 0px",
});

export const previewOptionBottomSection = s({
  flex: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 8px",
  gap: 36,
});

export const previewOptionDescription = s({
  width: 56,
  height: 56,
  backgroundColor: "#DEDEDE",
  borderRadius: 28,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 16,
  lineHeight: "16px",
  fontWeight: 500,
});

export const previewOptionSwipeButton = s({
  width: "100%",
  backgroundColor: Styles.colors.black1,
  borderRadius: 16,
  display: "flex",
  padding: 8,
  justifyContent: "center",
  alignItems: "center",
  color: Styles.colors.white,
});

export const matchupName = s({
  textAlign: "center",
  fontSize: 16,
  color: Styles.colors.black1,
  marginBottom: 4,
});

export const matchupTime = s({
  textAlign: "center",
  fontSize: 12,
  color: Styles.colors.darkTranslucent750,
  marginBottom: 36,
});

export const optionLabel = s({
  fontSize: 24,
  textAlign: "center",
  fontWeight: 700,
  marginBottom: 16,
});
export const colorRow = s({
  display: "flex",
  gap: 8,
  alignItems: "center",
  marginBottom: 16,
});

export const colorBox = s({
  height: "100px !important",
  width: "100px !important",
  marginRight: 12,
  $nest: {
    "& .react-colorful__pointer": {
      width: 21,
      height: 21,
    },
  },
});
